require('./bootstrap');
//require('alpinejs');                    // AlpineJS - Disable due to issue with loading. Need massive JS rework
require('admin-lte/dist/js/adminlte');  // AdminLTE
var Chart = require('chart.js')         // Chart.JS
autosize = require('autosize');         // Autosize
window.Swal = require('sweetalert2');   // SweetAlert
var DataTable = require('datatables.net');

PhotoSwipe = require('photoswipe/dist/photoswipe');
PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');
Croppie = require('croppie');

require('select2/dist/js/select2');     // Select2

daterangepicker = require('daterangepicker');   // Daterangepicker

var tinymce = require('tinymce/tinymce');       // TinyMCE
require('tinymce/icons/default');               // TinyMCE Icons
require('tinymce/themes/silver');               // TinyMCE Themes

// TinyMCE Plugins
require('tinymce/plugins/paste');
require('tinymce/plugins/link');
